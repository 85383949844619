export const checkUsername = (username: string) => {
    const format = /^[a-zA-Z0-9]*$/;
    // const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    if (username == '') {
        return false;
    } else if (format.test(username)) {
        return true;
    } else {
        return false;
    }
};

export const checkInputMessage = (inputMessage: string) => {
    const format = /<[^>\s]+/;
    if (format.test(inputMessage)){
        return false;
    } else {
        return true;
    }
}
