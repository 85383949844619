import { Route, Routes, Outlet, useParams } from 'react-router-dom';
import { stagingRoutes } from './config/stagingPath';
import { localRoutes } from './config/localPath';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { productionRoutes } from './config/productionPath';
import { productionRoutesDR1 } from './config/productionPathDR1';
import { productionRoutesDR2 } from './config/productionPathDR2';
import { productionRoutesDR3 } from './config/productionPathDR3';
import { routing } from './config/routing'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import GlobalServices from './context/GlobalServices';
import Main from './element/Main';
import Loading from './element/Loading';

function App() {
    console.log(process.env.REACT_APP_DEPLOY_ENV, productionRoutes);

    const [routes, setRoutes] = useState<{ path: string; component: JSX.Element }[]>([]);
    const [loading, setLoading] = useState(true); // Initially loading

    const clientApi = process.env.REACT_APP_DEPLOY_ENV === 'local'
        ? 'http://localhost:3001/getConfigPath'
        : `${window.location.origin}/nodeApi/getConfigPath`;

    let routeComponents;

    //const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        (async () => {
            try {
                //await delay(2000); // 2000ms delay (2 seconds)
                
                const apiUrl = clientApi;
                const url = new URL(window.location.href);
                
                // Remove all query parameters
                url.search = '';

                let pathnameParts = url.pathname.split('/');

                //Remove any symbol after the domain name
                let cleanedPathname = pathnameParts.slice(0, 2).join('/');
    
                // Use the URL without the query parameters
                const cleanedUrl = url.origin + cleanedPathname;
    
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ path: cleanedUrl }),
                });
    
                const data = await response.json();
    
                const apiRoutes = [
                    {
                        path: data.client,
                        component: (
                            <GlobalServices
                                acctId={data.acctId}
                                botId={data.botId}
                                apiPaths={[data.apiPaths]}
                            >
                                <Main
                                    acctId={data.acctId}
                                    botId={data.botId}
                                    brandId={data.brandId}
                                    dbProfile={data.dbProfile}
                                    client={data.client}
                                    title={data.title}
                                    apiPaths={[data.apiPaths]}
                                    pluginPaths={[data.pluginPaths]}
                                    socketPaths={data.socketPaths}
                                />
                            </GlobalServices>
                        ),
                    },
                ];
    
                setRoutes(apiRoutes);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false); // Stop loading once the data is fetched
            }
        })();
    }, []);

    if (loading) {
        return (
            <Provider store={store}>
                <Loading
                    isLoading={loading}
                    customizationType="wave"
                    customizationColor="bg-slate-300"
                    isCustomizationColor={true}
                />
            </Provider>
        ); // Show loading state
    }
    


    if (process.env.REACT_APP_DEPLOY_ENV == 'production' && routing['deployment'] == true) {

        console.log("Routing is true, routing to server", routing['routeToServer']);

        routeComponents =
        routing['routeToServer'] == 'dr1'
            ? productionRoutesDR1.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            : routing['routeToServer'] == 'dr2'
            ? productionRoutesDR2.map(({ path, component }, key) => (
                  <Route path={path} element={component} key={key} />
              )): routing['routeToServer'] == 'dr3'
              ? productionRoutesDR3.map(({path,component}, key) => (
                  <Route path={path} element={component} key={key} />
              ))
            :null;

    } else {

        // routeComponents =
        // process.env.REACT_APP_DEPLOY_ENV == 'production'
        //     ? productionRoutes.map(({ path, component }, key) => (
        //           <Route path={path} element={component} key={key} />
        //       ))
        //     : (process.env.REACT_APP_DEPLOY_ENV == 'staging'
        //     ? stagingRoutes.map(({ path, component }, key) => (
        //           <Route path={path} element={component} key={key} />
        //       ))
        //     : (process.env.REACT_APP_DEPLOY_ENV == 'local'
        //     ? localRoutes.map(({ path, component }, key) => (
        //           <Route path={path} element={component} key={key} />
        //       ))
        //     : null));
        // const [isLoading, setIsLoading] = useState(true);

        // let routes: { path: string; component: JSX.Element }[] = [];

        // if (process.env.REACT_APP_DEPLOY_ENV == 'production') {
        //     routes = productionRoutes
        // } else if (process.env.REACT_APP_DEPLOY_ENV == 'staging') {
        //     routes = stagingRoutes
        // } else if (process.env.REACT_APP_DEPLOY_ENV == 'local') {
        //     routes = localRoutes
        // }

        routeComponents = routes.map(({ path, component}, key) => (
            <Route path={path} element={component} key={key} />
        ));

        
    }

    console.log("routeComponents", routeComponents);


    return (
        <Provider store={store}>
            <Routes>
                <Route path="/">
                    {routeComponents}
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </Provider>
    );

    

}

function Home() {
    return <div>This is Home page</div>;
}

function NoMatch() {
    return (
        <div>
            <h2>Invalid Path</h2>
        </div>
    );
}

export default App;


    // const routeComponents =
    //     process.env.REACT_APP_DEPLOY_ENV == 'production'
    //         ? productionRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : (process.env.REACT_APP_DEPLOY_ENV == 'staging'
    //         ? stagingRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : (process.env.REACT_APP_DEPLOY_ENV == 'local'
    //         ? localRoutes.map(({ path, component }, key) => (
    //               <Route path={path} element={component} key={key} />
    //           ))
    //         : null));