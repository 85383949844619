import React, { createContext, FC } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useSocket } from '../hook/useSocket';
import { error } from '../utils/console';
import { getCookies, removeCookie, setCookies } from '../utils/cookies';
import { post } from '../utils/rest';
import { generateToken } from '../utils/tokenGenerator';

export const SessionServiceContext = createContext({} as any);

const SessionService: FC = ({ children }: any) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const sessionService = {
        appendToken: (token: any) => {
            var newurl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname +
                window.location.search +
                (window.location.search.length > 0 ? `&token=${token}` : `?token=${token}`);
            window.history.pushState({ path: newurl }, '', newurl);
        },
        replaceToken: (token: any) => {
            const url = new URL(window.location.href);
        
            // Only replace the token if it exists in the search parameters
            if (url.searchParams.has('token')) {
                url.searchParams.set('token', token);
                
                // Update the browser history without reloading the page
                const newUrl = decodeURIComponent(url.href); // Ensure the URL remains decoded properly
                window.history.pushState({ path: newUrl }, '', newUrl);
            }
        },
        getCacheSession: async (acctId:number, botId:number) => {
            return new Promise((resolve, reject) => {
                const cookiesKey =
                    acctId.toString() +
                    ('00' + botId).slice(-3) +
                    '_token';
                resolve(getCookies(cookiesKey));
            });
        },
        removeCacheSession: async (acctId:number, botId:number) => {
            return new Promise((resolve, reject) => {
                const cookiesKey =
                    acctId.toString() +
                    ('00' + botId).slice(-3) +
                    '_token';
                resolve(removeCookie(cookiesKey));
            });
        },
        createSession: async (acctId:number, botId:number) => {
            return new Promise((resolve, reject) => {
                const token = generateToken();
                const cookiesKey =
                    acctId.toString() +
                    ('00' + botId).slice(-3) +
                    '_token';
                setCookies(cookiesKey, token); 
                sessionService.appendToken(token)
                resolve(token);
            });
        }
    };

    return (
        <>
            <SessionServiceContext.Provider value={sessionService}>
                {children}
            </SessionServiceContext.Provider>
        </>
    );
};

export default SessionService;
