import forge from 'node-forge';

export const getEncrypt64 = (data: any, authPublicKey: any) => {
    try {
        const publicKey = forge.pki.publicKeyFromPem(authPublicKey);

        var dataToEncrypt = JSON.stringify(data)

        // Encrypt the JSON string
        var encrypted = publicKey.encrypt(dataToEncrypt, 'RSA-OAEP', {
                    md: forge.md.sha256.create(),
                    mgf1: {
                        md: forge.md.sha1.create()
                    }
                })

        const encryptedBase64 = forge.util.encode64(encrypted);

        return encryptedBase64

    } catch (error) {
        console.error('Error during encryption:', error);
        throw error;
    };
}



