import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import Button from '../../component/Button';
import { checkUsername } from '../../helper/common';
import { error, warning } from '../../utils/console';
import { useCacheService } from '../../hook/useCacheService';
import { useDataService } from '../../hook/useDataService';
import { useSocket } from '../../hook/useSocket';
import { editSession, editSessions } from '../../redux/state/session';
import Background from '../Background';

interface PrechatProps {
    height: number;
}

const PrechatModal = (props: PrechatProps) => {
    const { sendCheckVipEvent } = useSocket();

    const isPrechat = useSelector(
        (state: { status: { isPrechat: Boolean } }) => state.status.isPrechat
    );
    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );
    const enablePrechat = useSelector(
        (state: { settings: { enablePrechat: Boolean } }) =>
            state.settings.enablePrechat
    );
    const prechatBannerUrl = useSelector(
        (state: { settings: { preChatBannerUrl: string } }) =>
            state.settings.preChatBannerUrl
    );
    const prechatGreetingMessage = useSelector(
        (state: { settings: { greetingMessage: string } }) =>
            state.settings.greetingMessage
    );
    const prechatSubmitButton = useSelector(
        (state: { settings: { submitButtonText: string } }) =>
            state.settings.submitButtonText
    );
    const prechatPlaceholder = useSelector(
        (state: { settings: { usernamePlaceholder: string } }) =>
            state.settings.usernamePlaceholder
    );

    const botButtonColor = useSelector(
        (state: { settings: { botButtonColor: string } }) =>
            state.settings.botButtonColor
    );

    const sessionId = useSelector(
        (state: { session: { sessionId: string } }) => state.session.sessionId
    );

    const botBgImage = useSelector(
        (state: { settings: { botBgImage: string } }) =>
            state.settings.botBgImage
    );

    const botBgImageOpacity = useSelector(
        (state: { settings: { botBgImageOpacity: number } }) =>
            state.settings.botBgImageOpacity
    );

    const botBgImageFormat = useSelector(
        (state: { settings: { botBgImageFormat: number } }) =>
            state.settings.botBgImageFormat
    );

    const invalidUsernameAlertMessage = useSelector(
        (state: {settings: {invalidUsernameAlertMessage: string}}) =>
            state.settings.invalidUsernameAlertMessage
    )

    const customValues = useSelector(
        (state: { settings: { customValues: string } }) => state.settings.customValues
    );

    const invalidUsernameTemp = "Invalid Username. Use alphabets or numbers only"

    const { setCacheByField } = useCacheService();

    const dataService = useDataService();

    const dispatch = useDispatch();

    const [inputUsername, setInputUsername] = useState('');

    const [isValidUsername, setIsValidUsername] = useState(false);

    const [isInvalidUsername, setIsInvalidUsername] = useState(false);

    useEffect(() => {
        if (checkUsername(inputUsername)) {
            setIsValidUsername(true);
            setIsInvalidUsername(false);
        } else {
            setIsValidUsername(false);

            if (inputUsername!= ''){
                setIsInvalidUsername(true);
            }
        }
    }, [inputUsername]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (isValidUsername) {
                handleSubmit();
            } else {
                warning(PrechatModal.name, 'Invalid username', inputUsername);
            }
        }
    };

    const handleInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        // regex only text and number
        setInputUsername(event.target.value);
    };

    const handleClick = () => {
        if (checkUsername(inputUsername)) {
            handleSubmit();
        } else {
            warning(PrechatModal.name, 'Invalid username', inputUsername);
        }
    };

    const handleSubmit = () => {
        dispatch(editSession({ name: 'username', value: inputUsername }));
        // console.log('setCacheByField', sessionId, 'username', inputUsername);
        setCacheByField(sessionId, 'username', inputUsername);
        let data = JSON.parse(customValues);
        sendCheckVipEvent({
            chatId: sessionId,
            username: inputUsername,
            usernameFromUrl: false, //check
            recheckVip: false,
            merchant: data.hasOwnProperty('merchant') ? data.merchant : "",
            market: data.hasOwnProperty('market') ? data.market : "",
            checkVipDetails: data['check_vip_details'] ? data['check_vip_details'] : null,
            bbuPromoApiUrl: data.hasOwnProperty('bbu_promo_api_url') ? data.bbu_promo_api_url : "",
            bbuPromoDefaultUrl: data.hasOwnProperty('bbu_promo_default_url') ? data.bbu_promo_default_url : "",
        });
    };

    // console.log("PRECHAT", username, Boolean(username))

    return (
        <div
            // style={{
            //     backgroundImage: botBgImage?`url(${botBgImage})`:'',
            //     backgroundSize: (botBgImageFormat == 0) ? 'repeat' : (botBgImageFormat == 1) ? 'cover' : (botBgImageFormat == 2) ? 'contain' : 'contain',

            // }}
            style={{ height: props.height }}
            className={classNames(
                'fixed w-full bg-fixed flex justify-center items-center z-20 bg-white overflow-auto',
                { hidden: !(enablePrechat && !Boolean(username)) || username!=="" }
            )}
        >
            <div
                className="absolute top-0 w-full h-full bg-fixed -z-10"
                style={{
                    backgroundImage: botBgImage ? `url(${botBgImage})` : '',
                    backgroundSize:
                        botBgImageFormat == 0
                            ? 'repeat'
                            : botBgImageFormat == 1
                            ? 'cover'
                            : botBgImageFormat == 2
                            ? 'contain'
                            : 'contain',
                    opacity: botBgImageOpacity / 100,
                }}
            ></div>
            <div className="w-90pc xs:w-64 small:w-96 medium:w-128 bg-white shadow-lg rounded-xl overflow-hidden">
                <div className="header w-full h-30pc bg-slate-200">
                    <img
                        src={prechatBannerUrl}
                        alt=""
                        className="w-full h-full object-cover"
                    />
                </div>
                <div className="content text-center text-sm small:text-base medium:text-lg">
                    <p className="text-center p-10">{prechatGreetingMessage}</p>

                    {isInvalidUsername == true && (
                        <p className = "text-sm medium:text-lg text-red-500 font-medium">
                            {invalidUsernameAlertMessage? invalidUsernameAlertMessage: invalidUsernameTemp}
                        </p>
                    )}
                    <input
                        onKeyDown={handleKeyDown}
                        onChange={handleInputChange}
                        className={classNames(
                            'bg-slate-200 text-center px-4 py-2 w-80pc rounded-lg text-slate-800 focus:outline-none',
                            {
                                'border-2 border-red-300': !isValidUsername,
                            }
                        )}
                        placeholder={prechatPlaceholder}
                        value={inputUsername}
                        type="search"
                        autoComplete="off"
                        maxLength={100}
                    />
                    {/* <p className='text-lg text-center p-10'>{prechatGreetingMessage}</p> */}
                    <Button
                        fns={[{ type: 'js', func: handleClick }]}
                        className={classNames(
                            'w-80pc px-4 py-2 rounded-lg mx-auto mb-6 mt-2 text-white font-bold',
                            {
                                'bg-sky-500':
                                    !botButtonColor && isValidUsername,
                                'bg-gray-300': !isValidUsername,
                            }
                        )}
                        style={{
                            backgroundColor: botButtonColor
                                ? botButtonColor.slice(0, 7)
                                : '',
                        }}
                        disabled={!isValidUsername}
                    >
                        {prechatSubmitButton}
                    </Button>
                </div>
            </div>
        </div>
    );
};

PrechatModal.propTypes = {};

export default PrechatModal;
