import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { editStatus } from '../../redux/state/status';
import { SendIntentEventInterface } from '../../utils/interfaces';
import { useSocket } from '../../hook/useSocket';
import { getOS } from '../../utils/navigator';

interface Props {
    height: number;
}
const LoginIframeModal = (props: Props) => {
    // console.log("IMAGE MODAL HERE")

    const isLoginIframeModal = useSelector(
        (state: { status: { isLoginIframeModal: boolean } }) =>
            state.status.isLoginIframeModal
    );

    const socket = useSocket();

    const loginIframeUrl = useSelector(
        (state: { status: { loginIframeUrl: string } }) =>
            state.status.loginIframeUrl
    );

    const intentId = useSelector(
        (state: { status: { intentId: string } }) => state.status.intentId
    );

    const chatId = useSelector(
        (state: { session: { chatId: string } }) => state.session.chatId
    );

    const username = useSelector(
        (state: { session: { username: string } }) => state.session.username
    );

    const botLanguage = useSelector(
        (state: { settings: { botLanguage: string } }) =>
            state.settings.botLanguage
    );

    const processor = useSelector(
        (state: { settings: { processor: number } }) =>
            state.settings.processor
    );

    const dispatch = useDispatch();

    const handleClick = () => {
        // console.log("Click close login iframe modal")
        dispatch(editStatus({ name: 'isLoginIframeModal', value: false }));
        dispatch(editStatus({ name: 'loginIframeUrl', value: '' }));
        let data = {
            username: username,
            chatId: chatId,
            type: 'goToIntent',
            intentId: intentId,
            triggerTime: new Date().toJSON(),
            platform: 'mario',
            group_name: '',
            customer_lang_culture:
                botLanguage.toLowerCase() === 'chinese'
                    ? 'zh-cn'
                    : botLanguage.toLowerCase() === 'thai'
                    ? 'th-th'
                    : botLanguage.toLowerCase() === 'vietnamese'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'vietnam'
                    ? 'vi-vn'
                    : botLanguage.toLowerCase() === 'english'
                    ? 'en'
                    : botLanguage.toLowerCase(),
            operating_system: getOS(navigator),
            screen_resolution: window.screen.width + '*' + window.screen.height,
            processor: processor
        } as SendIntentEventInterface;
        // console.log(data)
        socket.sendIntentEvent(data);
    };

    return (
        <div
            style={{ height: props.height }}
            className={classNames(
                'fixed w-screen bg-fixed flex justify-center medium:items-center z-20 bg-zinc-800',
                { hidden: !isLoginIframeModal }
            )}
        >
            {/* <div className="absolute right-4 top-4">
                <img
                    onClick={handleClick}
                    alt="icon"
                    src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
                    className="h-10 cursor-pointer"
                />
            </div> */}

            <div className="relative">
                <img
                    onClick={handleClick}
                    alt="icon"
                    src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
                    className="absolute right-2 top-4 cursor-pointer w-8"
                />

                <iframe
                    className="w-screen medium:min-w-[600px] medium:w-[600px] h-full medium:min-h-[500px] medium:h-[500px] bg-slate-200 overflow-scroll"
                    src={loginIframeUrl}
                />
                {/* <iframe className='w-screen medium:w-[600px] h-full medium:h-[500px] bg-slate-200 overflow-scroll' src={'https://www.wikipedia.com'} /> */}
            </div>
        </div>
    );
};

LoginIframeModal.propTypes = {};

export default LoginIframeModal;
