import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { editStatus } from '../redux/state/status';

interface TypingMessageProps {
    owner: 'user' | 'bot' | 'agent' | 'system' | 'null';
    name: string;
    isNewOwner: boolean;
}

const TypingMessage = (props: TypingMessageProps) => {

    const [dots, setDots] = useState('...');

    const botChatBoxBgColor = useSelector(
        (state: { settings: { botChatBoxBgColor: string } }) =>
            state.settings.botChatBoxBgColor
    );
    const botTextColor = useSelector(
        (state: { settings: { botTextColor: string } }) =>
            state.settings.botTextColor
    );

    const botAvatar = useSelector(
        (state: { settings: { botAvatar: string } }) => state.settings.botAvatar
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setDots((prev) => {
                if (prev.length < 3) {
                    return prev + '.';
                }
                return '';
            });
        }, 500); // Adjust the speed of the animation

        return () => clearInterval(interval);
    }, []);

    if (props.owner === "null") {
        return null; // or <></> if you prefer using a fragment
    }

    return (
        <div
            className={classNames(
                'flex items-start my-0.5 font-medium max-w-80pc',
                {
                    'flex-row justify-start mr-20pc ml-4': 
                        props.owner === 'bot' || props.owner === 'agent',
                },
                {
                    'flex-row-reverse justify-end ml-20pc mr-4': 
                        props.owner === 'user',
                }
            )}
        >
            {/* Always render avatar */}
            <img
                src={props.isNewOwner
                    ? "https://scontent.fkul3-4.fna.fbcdn.net/v/t39.30808-6/241286758_10159729502174429_2950282959013948886_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=euJfafbdwI4AX8s_l2l&_nc_ht=scontent.fkul3-4.fna&oh=00_AT8Jk2bfjyqt95Im0gdlpsocYd8tHq-eTaweaf19FJE4hQ&oe=621B6748"
                    : botAvatar}
                alt="Avatar"
                className="w-8 h-8 medium:w-12 medium:h-12 rounded-full mt-4 shadow-lg"
            />
    
            {/* Always render typing message */}
            <div
                className={classNames(
                    'flex flex-col text-xs max-w-xl mx-2 w-full',
                    { 'items-start': props.owner === 'bot' || props.owner === 'agent' },
                    { 'items-end': props.owner === 'user' }
                )}
            >
                <div className="text-xs mobile-s:text-xs sm:text-sm font-normal text-slate-500 mt-2 invisible">
                    {props.name}
                </div>
                <div
                    className={classNames(
                        'text-sm mobile-s:text-sm md:text-lg px-4 py-2 rounded-lg inline-block bg-slate-300 text-white max-w-90pc break-words shadow-sm animate-fade',
                        {
                            'rounded-tl-none': props.owner === 'bot' || props.owner === 'agent',
                        },
                        { 'rounded-tr-none': props.owner === 'user' }
                    )}
                    style={{
                        backgroundColor: `${botChatBoxBgColor ? botChatBoxBgColor.slice(0, 7) : 'transparent'}`,
                        color: `${botTextColor ? botTextColor.slice(0, 7) : 'inherit'}`,
                    }}
                >
                    {/* Typing animation */}
                    <li 
                        className="slds-chat-listitem slds-chat-listitem_inbound"
                        style={{ listStyleType: 'none'}}
                    >
                        <div className="slds-chat-message">
                            <div className="slds-chat-message__body">
                                <div className="slds-chat-message__text slds-chat-message__text_inbound slds-chat-message__text_sneak-peek">
                                    <span title="The chat visitor is typing" className="slds-icon-typing slds-is-animated">
                                        {/* Typing animation dots */}
                                        <span className="slds-icon-typing__dot" style={{
                                            display: 'inline-block',
                                            width: '6px',
                                            height: '6px',
                                            marginRight: '5px',
                                            borderRadius: '50%',
                                            backgroundColor: botTextColor,
                                            animation: 'wave 1.2s infinite ease-in-out',
                                        }}>
                                        </span>
                                        <span className="slds-icon-typing__dot" style={{
                                            display: 'inline-block',
                                            width: '6px',
                                            height: '6px',
                                            marginRight: '5px',
                                            borderRadius: '50%',
                                            backgroundColor: botTextColor,
                                            animation: 'wave 1.2s infinite ease-in-out 0.2s',  // Delayed by 0.2s
                                        }}>
                                        </span>
                                        <span className="slds-icon-typing__dot" style={{
                                            display: 'inline-block',
                                            width: '6px',
                                            height: '6px',
                                            marginRight: '5px',
                                            borderRadius: '50%',
                                            backgroundColor: botTextColor,
                                            animation: 'wave 1.2s infinite ease-in-out 0.4s',  // Delayed by 0.4s
                                        }}>
                                        </span>

                                        {/* Hidden assistive text for accessibility */}
                                        {/* <span className="slds-assistive-text">The chat visitor is typing</span> */}
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* Keyframes for wave animation */}
                        <style>{`
                            @keyframes wave {
                                0%, 40%, 100% {
                                    transform: translateY(0);
                                }
                                20% {
                                    transform: translateY(-10px);
                                }
                                60% {
                                    transform: translateY(-5px);
                                }
                            }
                        `}</style>
                    </li>
                </div>
            </div>
        </div>
    );    
};

TypingMessage.propTypes = {};

export default TypingMessage;
